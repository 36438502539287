import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import classNames from 'clsx';
import React, { Component } from 'react';

type Props = {
  classes: Record<string, string>;
};

class IndexOrganization extends Component<Props> {
  render() {
    const { classes } = this.props;

    return (
      <section id="organization" className={classes.root}>
        <div className="title-wrapper">
          <h1 className="title">- organization -</h1>
        </div>
        <div className={classNames(classes.componentWrapper, 'corporate-logo')}>
          <img
            src="/assets/images/nadebear1.png"
            alt="nadebear"
            className={classes.image}
          />
          <div>
            <h2 className={classes.title}>
              一般社団法人 なでしこケア
              <br />
              （略称：なでケア）
            </h2>
            <div className={classes.address}>
              所在地： 東京都千代田区飯田橋三丁目2番10-701号
              <br />
              TEL：03-6268-9672
              <br />
              創業：2019年7月
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      backgroundColor: '#FD697A',
      padding: 20,
    },
    componentWrapper: {
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexWrap: 'wrap',
    },
    address: {
      lineHeight: 2,
      paddingBottom: 40,
    },
    image: {
      height: 400,
    },
    title: {
      fontSize: 30,
      [theme.breakpoints.down('sm')]: {
        fontSize: 26,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 18,
      },
    },
  });

export default withStyles(styles)(IndexOrganization);
