import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';

type Props = {
  classes: {
    captionWrapper: string;
    caption: string;
    root: string;
    right: string;
    youtubeWrap: string;
    youtube: string;
    left: string;
    container: string;
    message: string;
    label: string;
    subImageContainer: string;
    subImageWrapper: string;
    subImage: string;
  };
};

class IndexAboutUs extends Component<Props> {
  render() {
    const { classes } = this.props;

    return (
      <section id="about-us">
        <div className={classes.root}>
          <div className={classes.right}>
            <div className={classes.youtubeWrap}>
              <iframe
                className={classes.youtube}
                width="560"
                height="315"
                src="https://www.youtube.com/embed/18rIA_L5IHo"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="なでケア紹介動画"
              />
            </div>
          </div>
          <div className={classes.left}>
            <div className={classes.container}>
              <div>
                <h2>
                  なでしこの成長を、
                  <br />
                  社会の元気に。
                </h2>
              </div>
              <div className={classes.message}>
                <p>
                  「女子サッカーを文化に。」
                  <br />
                  そんな未来のために、私たち選手がもっと成長しなければいけない。
                  <br />
                  <br />
                  ピッチ外での新しい出会いや経験を通じて、
                  <br />
                  選手を超え、ひとりの「ヒト」として成長していく。
                  <br />
                  <br />
                  そして、成長した私たちが、
                  <br />
                  女子サッカーや社会を元気にしていく。
                </p>
              </div>
              <div className={classes.message}>
                <p>
                  なでケアは、ヒトとしての成長を望む選手が集まり、
                  <br />
                  女子サッカーや社会のためにアクションを起こしていく
                  <br />
                  温かく優しいコミュニティーです。
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.subImageContainer}>
          <img
            src="/assets/images/top1.webp"
            alt="top1"
            className={classes.subImage}
          />
          <img
            src="/assets/images/top2.webp"
            alt="top2"
            className={classes.subImage}
          />
        </div>
      </section>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
    },
    container: {
      paddingLeft: 40,
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 20,
        paddingRight: 20,
      },
    },
    captionWrapper: {
      marginLeft: 'auto',
      marginRight: 'auto',
      paddingLeft: 40,
      paddingBottom: 20,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 20,
      },
    },
    caption: {
      fontSize: 60,
      lineHeight: 1.4,
      letterSpacing: 20,
      [theme.breakpoints.down('sm')]: {
        fontSize: 30,
        letterSpacing: 18,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 18,
        letterSpacing: 14,
      },
    },
    message: {
      paddingBottom: 20,
    },
    label: {
      backgroundColor: '#eee',
      fontSize: 40,
      padding: 30,
      marginBottom: 20,
      fontFamily:
        '"Sawarabi Gothic", -apple-system, BlinkMacSystemFont, "Helvetica Neue", YuGothic, "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif',
      lineHeight: 1.4,
      [theme.breakpoints.down('sm')]: {
        fontSize: 30,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 18,
      },
    },
    left: {
      marginRight: 'auto',
      marginLeft: 'auto',
    },
    right: {
      marginRight: 'auto',
      marginLeft: 'auto',
      marginBottom: 40,
      width: 560,
    },
    youtubeWrap: {
      position: 'relative',
      paddingBottom: '56.25%',
    },
    youtube: {
      position: 'absolute',
      top: 0,
      right: 0,
      width: '100%',
      height: '100%',
    },
    subImageContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
    subImage: {
      width: 500,
      height: 250,
      objectFit: 'cover',
      padding: 10,
      opacity: 0.7,
    },
  });

export default withStyles(styles)(IndexAboutUs);
