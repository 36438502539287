import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import classNames from 'clsx';
import React, { Component } from 'react';
import LazyLoad from 'react-lazyload';

type Props = {
  classes: {
    root: string;
    componentWrapper: string;
    messageContainer1: string;
    messageContainer2: string;
    message1: string;
    message2: string;
    image: string;
    label: string;
    backgroundColorWhite: string;
    backgroundColor: string;
    profile: string;
    messageTitle: string;
  };
};

class IndexMessage extends Component<Props> {
  render() {
    const { classes } = this.props;

    return (
      <section id="message" className={classes.root}>
        <div className={classNames(classes.componentWrapper, 'title-wrapper')}>
          <h1 className="title">- message -</h1>
        </div>
        <div className={classes.backgroundColor}>
          <div
            className={classNames(
              classes.componentWrapper,
              classes.messageContainer1
            )}
          >
            <div className={classes.image}>
              <LazyLoad offset={200} once>
                <img src="/assets/images/kumagai.jpg" alt="代表挨拶" />
              </LazyLoad>
            </div>
            <div className={classNames(classes.message1, 'sawarabi-gothic')}>
              <div>
                <h3 className={classes.messageTitle}>
                  "女子サッカーが多くの少女が夢見る職業に"
                </h3>
                <div>
                  <p>
                    20歳でドイツに渡り、ドイツ・フランスでプレーする㆗で、私はこのことを強く願うようになりました。私たちなでしこは、「なでしこみたいになりたい！」又は「なでしこを目指したい！」と、多くの女の子に思われるような存在であるべきだと思います。
                  </p>
                  <p>
                    そんな存在になるために、私たちなでしこに何ができるか。どうやって自分たちの価値を高めていけるか、ということを「なでケア」を通じでみんなで考え、行動していきたいと思っています。
                  </p>
                </div>
              </div>
              <div className={classes.profile}>
                <div className="mr20">代表理事</div>
                <div>
                  <div>熊谷 紗希</div>
                  <div className="barrio">SAKI KUMAGAI</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.backgroundColorWhite}>
          <div
            className={classNames(
              classes.componentWrapper,
              classes.messageContainer2
            )}
          >
            <div className={classes.image}>
              <LazyLoad offset={200} once>
                <img src="/assets/images/kondo.jpg" alt="理事挨拶" />
              </LazyLoad>
            </div>
            <div className={classNames(classes.message2, 'sawarabi-gothic')}>
              <div>
                <h3 className={classes.messageTitle}>
                  "なでしこが元気を届ける存在に"
                </h3>
                <div>
                  <p>
                    私はサッカーを通じて多くの人に出会い、様々な経験をしてきました。そして”なでしこジャパン”では、沢山の方に支えられ、応援して頂きました。その㆗で自分たちには勇気や元気を届けられる力があるかもしれないと感じ、女子サッカーは様々な人達にパワーを送る存在であるべきだと思いました。
                  </p>
                  <p>
                    サッカーやスポーツの枠には観る・やる・携わる・触れる・支えるなど沢山の関わり方があります。そこで『なでケア』を通じて、多くの方々にパワーを届けると共に憧れるような存在になっていきたいと思っています。
                  </p>
                </div>
              </div>
              <div className={classes.profile}>
                <div className="mr20">理事</div>
                <div>
                  <div>近賀 ゆかり</div>
                  <div className="barrio">YUKARI KINGA</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.backgroundColor}>
          <div
            className={classNames(
              classes.componentWrapper,
              classes.messageContainer1,
              'pt40'
            )}
          >
            <div className={classes.image}>
              <LazyLoad offset={200} once>
                <img src="/assets/images/otaki.jpg" alt="創設者挨拶" />
              </LazyLoad>
            </div>
            <div className={classNames(classes.message1, 'sawarabi-gothic')}>
              <div>
                <h3 className={classes.messageTitle}>
                  "ピッチの内外で活躍するなでしこを目指して"
                </h3>
                <div>
                  <p>
                    私は2011年W杯優勝の瞬間をテレビで見ていました。女子サッカーに対する考えが変わった瞬間でした。「あの頃のように、社会の㆗で役割を果たしたい。」そんな想いを原動力に動き出すと、たくさんの優しい想いがあることに気づきました。そしてその想いが、私をたくさんの仲間と繋げてくれました。
                  </p>
                  <p>
                    この想いを形にしていきたい。そしてその過程で出会う人や、積むことのできる経験で、より豊かな人生を築いてほしい。多くの人を優しく支え、そして時には支えられながら成⻑して行く。そんななでしこになっていきたいです。
                  </p>
                </div>
              </div>
              <div className={classes.profile}>
                <div className="mr20">創設者</div>
                <div>
                  <div>大滝 麻未</div>
                  <div className="barrio">AMI OTAKI</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      paddingTop: 40,
    },
    componentWrapper: {
      margin: '0 auto',
      maxWidth: 1000,
      paddingTop: 40,
      paddingBottom: 40,
    },
    messageContainer1: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      marginBottom: 20,
    },
    messageContainer2: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'row-reverse',
      paddingTop: 40,
    },
    message1: {
      width: '60%',
      lineHeight: 1.6,
      letterSpacing: 1.6,
      marginRight: 'auto',
      marginLeft: 'auto',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        paddingLeft: 20,
        paddingRight: 20,
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        paddingLeft: 20,
        paddingRight: 20,
      },
    },
    message2: {
      width: '60%',
      lineHeight: 1.6,
      letterSpacing: 1.6,
      marginRight: 'auto',
      marginLeft: 'auto',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        paddingLeft: 20,
        paddingRight: 20,
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        paddingLeft: 20,
        paddingRight: 20,
      },
    },
    image: {
      width: 300,
      height: 300,
      padding: 0,
      marginTop: 'auto',
      marginBottom: 'auto',
      marginRight: 'auto',
      marginLeft: 'auto',
      borderRadius: '50%',
      overflow: 'hidden',
      objectFit: 'fill',
    },
    label: {
      //textAlign: "right",
    },
    backgroundColorWhite: {
      backgroundColor: 'white',
    },
    backgroundColor: {
      backgroundColor: '#d4d4d4',
    },
    profile: {
      paddingBottom: 26,
      display: 'flex',
      textAlign: 'center',
      justifyContent: 'flex-end',
      fontWeight: 'bold',
    },
    messageTitle: {
      textAlign: 'center',
      paddingTop: 40,
      [theme.breakpoints.down('sm')]: {
        fontSize: 20,
      },
    },
  });

export default withStyles(styles)(IndexMessage);
