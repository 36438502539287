/* eslint-disable @typescript-eslint/no-explicit-any */
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import { graphql } from 'gatsby';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose, pure } from 'recompose';

import * as masterActions from '@/actions/master';
import AboutUs from '@/components/index/AboutUs';
import Hero from '@/components/index/Hero';
import Message from '@/components/index/Message';
import News from '@/components/index/News';
import Organization from '@/components/index/Organization';
import OurService from '@/components/index/OurService';
import Layout from '@/components/layout';
import SEO from '@/components/seo';

type Props = {
  requestProcess: void;
  completeProcess: void;
  isProcessing: boolean;
  data: any;
  wpModel: any;
  classes: {
    componentWrapper: string;
  };
};

type State = {
  news: Array<any>;
  stories: Array<any>;
};

class IndexPage extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      news: [],
      stories: [],
    };
  }

  render() {
    const { data, classes } = this.props;

    const news = filter(
      data.allWordpressPost.edges,
      (edge: any) =>
        !isEmpty(edge.node.categories) &&
        edge.node.categories[0].slug === 'news'
    );
    //const stories = filter(
    //  data.allWordpressPost.edges,
    //  (edge: any) =>
    //    !isEmpty(edge.node.categories) &&
    //    edge.node.categories[0].slug === 'stories'
    //);

    return (
      <Layout>
        <SEO title="トップ" />
        <div className={classes.componentWrapper}>
          <Hero />
        </div>
        <div className={classes.componentWrapper}>
          <AboutUs />
        </div>
        <Message />
        {!isEmpty(news) ? <News data={news} /> : null}
        <OurService />
        <Organization />
      </Layout>
    );
  }
}

const styles = (_theme: Theme) =>
  createStyles({
    componentWrapper: {
      margin: '0 auto',
      maxWidth: 1280,
      paddingTop: 0,
    },
  });

export const pageQuery = graphql`
  {
    allWordpressPost(
      sort: { order: DESC, fields: date }
      filter: {
        categories: { elemMatch: { slug: { regex: "/news|stories/" } } }
      }
    ) {
      edges {
        node {
          id
          title
          content
          excerpt
          date
          modified
          slug
          status
          featured_media {
            source_url
          }
          acf {
            author
          }
          categories {
            slug
          }
        }
      }
    }
  }
`;

function mapStateToProps(state: any) {
  const { isProcessing } = state.rootReducer.master;

  return {
    isProcessing,
    wpModel: state.rootReducer.wordpress,
  };
}

const connector = connect(mapStateToProps, { ...masterActions });

export default compose(pure, withStyles(styles), connector)(IndexPage);
