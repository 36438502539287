import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import classNames from 'clsx';
import { Link } from 'gatsby';
import React, { Component } from 'react';

type Props = {
  classes: {
    root: string;
    componentWrapper: string;
    seeAll: string;
    seeAllLink: string;
    seeAllText: string;
    newsTable: string;
    newsTableRow: string;
    newsTableCell: string;
    newsLink: string;
  };
  data: WPPost[];
};

type WPPost = {
  node: {
    id: string;
    title: string;
    excerpt: string;
    date: string;
    featured_media: {
      source_url: string | null;
    };
  };
};

class IndexNews extends Component<Props> {
  _formatDate(wordpressDate: string) {
    const dateObj = new Date(wordpressDate);
    const year = dateObj.getFullYear();
    const month = ('0' + (dateObj.getMonth() + 1)).slice(-2); // 月を2桁にする
    const day = ('0' + dateObj.getDate()).slice(-2); // 日を2桁にする
    return `${year}.${month}.${day}`;
  }

  render() {
    const { classes, data } = this.props;

    const firstNewsGroup = data.slice(0, 6);

    return (
      <section id="news" className={classes.root}>
        <div className={classNames('title-wrapper')}>
          <h1 className="title">- news -</h1>
        </div>
        <div className={classes.componentWrapper}>
          <Table className={classes.newsTable}>
            <TableBody>
              {firstNewsGroup.map(({ node }: WPPost) => {
                return (
                  <TableRow
                    key={node.id}
                    className={classNames(
                      classes.newsTableRow,
                      'sawarabi-gothic',
                      'animation',
                      'slide-in_news'
                    )}
                  >
                    <Link to={`/news/${node.id}`} className={classes.newsLink}>
                      <TableCell className={classNames(classes.newsTableCell)}>
                        {this._formatDate(node.date)}
                      </TableCell>
                      <TableCell className={classes.newsTableCell}>
                        {node.title}
                      </TableCell>
                    </Link>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
        <div className={classes.seeAll}>
          <Link className={classes.seeAllLink} to={'/news'}>
            <div className={classNames(classes.seeAllText, 'barrio')}>
              VIEW ALL
            </div>
          </Link>
        </div>
      </section>
    );
  }
}

const styles = (_theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: '#F9F9F9',
      paddingBottom: 40,
      paddingTop: 40,
    },
    componentWrapper: {
      margin: '0 auto',
      maxWidth: 1280,
      paddingTop: 0,
      padding: 20,
      display: 'flex',
      justifyContent: 'center',
    },
    newsTable: {
      width: 800,
    },
    seeAll: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: 70,
    },
    seeAllLink: {
      width: 120,
      height: 40,
      backgroundColor: '#F9F251',
      textDecoration: 'none',
      justifyContent: 'center',
      display: 'inline-block',
      textAlign: 'center',
    },
    seeAllText: {
      textDecoration: 'none',
      color: '#333',
      fontWeight: 'bold',
      fontSize: 20,
      transform: 'translateY(-50%)',
      position: 'relative',
      top: '50%',
      lineHeight: '20px',
      marginBottom: 0,
    },
    newsLink: {
      textDecoration: 'none',
      width: '100%',
    },
    listNewsTableDateTd: {
      width: '15%',
    },
    newsTableRow: {
      width: '100%',
      borderBottom: '1px solid #ccc',
    },
    newsTableCell: {
      padding: 20,
      width: 'auto',
      border: 'none',
      fontWeight: 'bold',
    },
  });

export default withStyles(styles)(IndexNews);
