import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import classNames from 'clsx';
import { Link } from 'gatsby';
import React, { Component } from 'react';

type Props = {
  classes: {
    root: string;
    backgroundColorWhite: string;
    componentWrapper: string;
    messageContainer1: string;
    messageContainer2: string;
    message1: string;
    image: string;
    label: string;
    backgroundColorPink: string;
    backgroundColor: string;
    message2: string;
    seeAll: string;
    seeAllLink: string;
    seeAllText: string;
  };
};

class IndexWhatWeDo extends Component<Props> {
  render() {
    const { classes } = this.props;

    return (
      <section id="what-we-do" className={classes.root}>
        <div className={classNames('title-wrapper')}>
          <h1 className={classNames('title', 'mt40')}>- our service -</h1>
        </div>
        <div
          className={classNames(classes.componentWrapper, 'sawarabi-gothic')}
        >
          <div>
            <img
              src="/assets/images/s1.png"
              alt="女子サッカー普及事業"
              className={classes.image}
            />
            <div className={classes.label}>女子サッカー普及事業</div>
          </div>
          <div>
            <img
              src="/assets/images/s2.png"
              alt="ソーシャルアクション事業"
              className={classes.image}
            />
            <div className={classes.label}>ソーシャルアクション事業</div>
          </div>
          <div>
            <img
              src="/assets/images/s3.png"
              alt="キャリアビルディング事業"
              className={classes.image}
            />
            <div className={classes.label}>キャリアビルディング事業</div>
          </div>
        </div>
        <div className={classes.seeAll}>
          <Link className={classes.seeAllLink} to={'/service'}>
            <div className={classNames(classes.seeAllText, 'barrio')}>
              VIEW MORE
            </div>
          </Link>
        </div>
      </section>
    );
  }
}
const styles = (_theme: Theme) =>
  createStyles({
    root: {
      paddingTop: 40,
      paddingBottom: 40,
      backgroundColor: 'white',
    },
    componentWrapper: {
      margin: '0 auto',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
    image: {
      width: 300,
      padding: '0, 20px',
      margin: '40px 20px',
    },
    seeAll: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: 70,
      marginTop: 80,
    },
    seeAllLink: {
      width: 120,
      height: 40,
      backgroundColor: '#F9F251',
      textDecoration: 'none',
      justifyContent: 'center',
      display: 'inline-block',
      textAlign: 'center',
    },
    seeAllText: {
      textDecoration: 'none',
      color: '#333',
      fontWeight: 'bold',
      fontSize: 20,
      transform: 'translateY(-50%)',
      position: 'relative',
      top: '50%',
      lineHeight: '20px',
      marginBottom: 0,
    },
    label: {
      textAlign: 'center',
    },
  });

export default withStyles(styles)(IndexWhatWeDo);
